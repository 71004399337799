import { CrossIcon, Menu4Icon } from 'fitify-ui/src/Icon/outline'
import { ModalSizeTypes } from 'fitify-ui/src/Modal/Modal.Types'
import { LinkTypes } from 'fitify-ui-landing/src/@types'
import { zIndexes } from 'fitify-ui-landing/src/theme/zIndexes'
import dynamic from 'next/dynamic'
import { useState } from 'react'

import {
  StyledNavigationButton,
  StyledNavigationMenu,
  StyledNavigationMobile,
  StyledNavigationMobileContainer,
} from './Navigation.Styled'
import NavigationMenu, { NavigationVariant } from './NavigationMenu'

const Modal = dynamic(() => import('fitify-ui/src/Modal/Modal'))

interface NavigationMobileProps {
  items: LinkTypes.Navigation[]
}

const NavigationMobile = ({ items }: NavigationMobileProps) => {
  const [isModalActive, setIsModalActive] = useState(false)

  return (
    <StyledNavigationMobileContainer>
      <StyledNavigationButton onClick={() => setIsModalActive((prev) => !prev)}>
        {isModalActive ? <CrossIcon /> : <Menu4Icon />}
      </StyledNavigationButton>

      <Modal
        show={isModalActive}
        handleClose={() => null}
        config={{
          modalSize: ModalSizeTypes.FULL,
          showOverlay: false,
          showLogo: true,
          showCloseButton: false,
          overlayIndex: zIndexes.mobileNavigation,
        }}
      >
        <StyledNavigationMobile>
          <StyledNavigationMenu>
            <NavigationMenu
              items={items}
              variant={NavigationVariant.MOBILE}
              onLinkClick={() => setIsModalActive(false)}
            />
          </StyledNavigationMenu>
        </StyledNavigationMobile>
      </Modal>
    </StyledNavigationMobileContainer>
  )
}

export default NavigationMobile
